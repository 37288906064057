<template>
  <div class="b2c-page-bg">
    <div class="b2c-o-h">
      <validation-observer ref="infoValid">
        <div class="fs-sp"></div>
        <div>
          <div class="b2c-pc-header">
            <h2 class="w-100">GIỎ HÀNG & THANH TOÁN</h2>
            <div class="nav2 d-none d-md-block"></div>
          </div>
          <div class="b2c-pc-pack-content">
            <div class="row mb-5">
              <div class="col-xl-8 col-lg-12 b2c-pc-payment">
                <h3 class="mb-1">Đơn hàng của bạn</h3>
                <h2 v-if="cart.length == 0" style="font-size: 2rem;text-align:center;">Không có sản phẩm nào</h2>
                <div class="cart" v-for="(p, stt) in cart.filter(i => i.product_type != 100)" :key="stt">
                  <div class="image">
                    <img :src="p.images[0].image_url" class="image-product" alt="">
                  </div>
                  <div class="info">
                    <div class="price_box">
                      <span class="price">{{ p.price | number }}đ</span></br>
                      <span class="base_price">{{ p.base_price | number }}đ</span>
                    </div>
                    <h2><span v-html="p.product_name"></span></h2>
                    <!--<div class="features" v-if="p.config">
                      <span v-for="(c,stt) in p.config.filter(i=>i.on_top==1)" :key="stt">{{c.name}} <b>{{p.attributes[c.code]}}</b></span>
                    </div>-->
                    <div class="item-single-info" v-if="p.origin_type == 'baseus' && p.variants.length > 1">

                      <div class="item-single-info__title">{{ $t("DevicePage.Select") }} {{
                          p.nameOption.toLowerCase()
                      }}:
                      </div>
                      <!-- <img class="variant-color" role="button" :src="p.variants[p.selectIndex].image_url"
                          style="width: 25px !important;height: 25px; border-radius: 50%;" /> -->
                      <div class="item-single-info__name">
                        &nbsp;{{ p.variants[p.selectIndex].title }}
                      </div>
                    </div>
                    <div class="features row" v-else-if="p.origin_type != 'baseus'">
                      <div class="col-12 pb-1" v-for="(c, stt) in p.variants" :key="stt">
                        <div class="row" v-if="c.variant_type == 'color'">
                          <div class="float-left color-text">{{ c.variant_name }}</div>
                          <div class="variant-color float-left" :style="'background:' + c.selected.value"></div>
                        </div>
                        <div class="row" v-else>{{ c.variant_name }} <b>{{ c.selected.value }}</b></div>
                      </div>
                    </div>
                    <div class="quantity">
                      <p>Số lượng</p>
                      <feather-icon size="20" icon="MinusCircleIcon" role="button" @click="changeAmount(p, -1)" />
                      <div class="amount">{{ p.amount }}</div>
                      <feather-icon size="20" icon="PlusCircleIcon" role="button" @click="changeAmount(p, 1)" />
                    </div>
                  </div>
                  <span class="remove" role="button" @click="removeProduct(p)">
                    <feather-icon size="20" icon="TrashIcon" /> Xoá sản phẩm
                  </span>
                </div>
                <div class="cart" v-for="p in cart.filter(i => i.product_type == 100)" :key="p.Phone">
                  <div class="image">
                    <img :src="require('@/assets/images/sim_itel.png')" alt="">
                  </div>
                  <div class="info">
                    <div class="price_box">
                      <span class="price">{{ p.PackPrice | number }}đ</span></br>
                      <span class="base_price"></span>
                    </div>
                    <h2>SIM DATA ITEL</h2>
                    <div class="features">
                      <span>Số thuê bao <b>{{ f087(p.Phone) }}</b></span>
                      <span>Gói cước <b>{{ p.Pack }}</b></span>
                    </div>
                    <div class="quantity disabled">
                      <p>Số lượng</p>
                      <feather-icon size="20" icon="MinusCircleIcon" />
                      <div class="amount">1</div>
                      <feather-icon size="20" icon="PlusCircleIcon" />
                    </div>
                  </div>
                  <span class="remove" role="button" @click="removeProduct(p)">
                    <feather-icon size="20" icon="TrashIcon" /> Xoá sản phẩm
                  </span>
                </div>
              </div>
              <div class="b2c-pc-info col-xl-4 col-lg-12">
                <b-card class="p-0" body-class="p-0">
                  <b-card-header>Thông tin người nhận</b-card-header>
                  <b-card-text>
                    <!--<validation-observer ref="infoValid">-->
                    <div class="row p-2">
                      <div class="col-md-12">
                        <validation-provider #default="{ errors }" rules="required|min:3|space">
                          <label class="d-block f-bold">Họ và tên Chủ thẻ<span class="text-primary">*</span></label>
                          <b-form-input maxlength="35" size="sm1" v-model="cart_info.fullName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Họ và tên Chủ thẻ (Đã đăng ký với ngân hàng)" />
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập đầy đủ họ và tên người
                            nhận</small>
                        </validation-provider>
                      </div>
                      <div class="col-md-12">
                        <validation-provider #default="{ errors }" rules="required|cccd|num">
                          <label class="d-block f-bold">Số CMND/CCCD Chủ thẻ<span class="text-primary">*</span></label>
                          <b-form-input maxlength="12" size="sm1"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                            v-model="cart_info.identityCode" :state="errors.length > 0 ? false : null"
                            placeholder="Số CMND/CCCD Chủ thẻ (Đã đăng ký với ngân hàng)" />
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập đầy đủ số CMND/CCCD
                            nhận</small>
                        </validation-provider>
                      </div>
                      <div class="col-md-12">
                        <validation-provider #default="{ errors }" rules="required|min:10">
                          <label class="d-block f-bold">Điện thoại liên hệ <span class="text-primary">*</span></label>
                          <b-form-input placeholder="Số điện thoại (Đang sử dụng)" size="sm1" type="text"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                            maxlength="10" v-model.trim="cart_info.ContactPhone"
                            :state="errors.length > 0 ? false : null" />
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập số điện thoại liên
                            hệ</small>
                        </validation-provider>
                      </div>
                      <div class="col-md-12">
                        <validation-provider #default="{ errors }" rules="required|email">
                          <label class="d-block f-bold">Email <span class="text-primary">*</span></label>
                          <b-form-input placeholder="E-mail (Đang sử dụng)" size="sm1" maxlength="40"
                            v-model.trim="cart_info.email" :state="errors.length > 0 ? false : null" />
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập chính xác email</small>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="w-100 b2c-cart-info-title border">
                      <span>Giao hàng tận nơi</span>
                    </div>
                    <div class="row p-2" v-if="!isLoading && cart_info.shipment_type == 'home'">
                      <div class="col-md-12">
                        <validation-provider #default="{ errors }" rules="required|min:2">
                          <label class="d-block f-bold">Địa chỉ <span class="text-primary">*</span></label>
                          <b-form-input size="sm1" placeholder="Số nhà, tên đường, tên thôn/ấp/khu" maxlength="40"
                            v-model="cart_info.addr" :state="errors.length > 0 ? false : null" />
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng điền chi tiết số nhà, tên đường,
                            thôn/ấp/khu</small>
                        </validation-provider>
                      </div>
                      <div class="col-6">
                        <validation-provider #default="{ errors }" rules="required|min0">
                          <label class="d-block f-bold">Tỉnh/TP <span class="text-primary">*</span></label>
                          <v-select @input="onCityChange" placeholder="Tỉnh / TP" label="text"
                            :class="errors.length > 0 ? 'is-invalid' : ''" v-model="CityId" :filter="fuseSearch"
                            :options="Cities" :reduce="CityId => CityId.id">
                          </v-select>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập Tỉnh/TP</small>
                        </validation-provider>
                      </div>
                      <div class="col-6">
                        <validation-provider #default="{ errors }" rules="required|min0">
                          <label class="d-block f-bold">Quận/Huyện <span class="text-primary">*</span></label>
                          <v-select :disabled="!(CityId > 0)" placeholder="Quận / Huyện"
                            :class="errors.length > 0 ? 'is-invalid' : ''" :filter="fuseSearch" label="text"
                            v-model="DistrictId" :options="Districts" :reduce="c => c.id"></v-select>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập Quận/Huyện</small>
                        </validation-provider>
                      </div>
                      <div class="col-6">
                        <validation-provider #default="{ errors }" rules="required|min0">
                          <label class="d-block f-bold">Phường/Xã <span class="text-primary">*</span></label>
                          <v-select :disabled="!(DistrictId > 0)" placeholder="Phường / xã"
                            :class="errors.length > 0 ? 'is-invalid' : ''" :filter="fuseSearch" label="text"
                            v-model="WardId" :options="Wards" :reduce="c => c.id">
                          </v-select>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập Phường/Xã</small>
                        </validation-provider>
                      </div>
                      <div class="col-6">
                        <validation-provider #default="{ errors }" rules="">
                          <label class="d-block">Ấp/Khu</label>
                          <v-select :disabled="!(WardId > 0)" placeholder="Ấp/Khu" :filter="fuseSearch" label="text"
                            v-model="HamletId" :options="Hamlets" :reduce="c => c.id"></v-select>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập Ấp/Khu</small>
                        </validation-provider>
                      </div>
                      <div class="col-12 mt-1 hidden" v-if="hasExpressShip">
                        <div class="d-flex space-between delivery-type">
                          <div class="info-delivery">
                            <div class="info-delivery__title">Giao hàng hỏa tốc</div>
                            <div class="info-delivery__sub-title">{{ expressShipInfo.deleveryTime }}</div>
                          </div>
                          <div class="check-box mr-3" @click="onClickExpress()">
                            <img v-if="isExpress" alt="checkbox" src="../assets/images/check-circle-red.png" />
                            <img v-else alt="checkbox" src="../assets/images/circleblank.png" />
                          </div>

                        </div>
                        <div class="border-bt"></div>
                      </div>
                      <div class="col-12 hidden">
                        <div class="d-flex space-between delivery-type">
                          <div class="info-delivery">
                            <div class="info-delivery__title">Giao hàng nhanh</div>
                            <div class="info-delivery__sub-title">Nhận hàng từ 1 tới 3 ngày kể từ thời điểm đặt đơn hàng
                            </div>
                          </div>
                          <div class="check-box mr-3" @click="onClickFast()">
                            <img v-if="isFast" alt="checkbox" src="../assets/images/check-circle-red.png" />
                            <img v-else alt="checkbox" src="@/assets/images/circleblank.png" />
                          </div>
                        </div>
                        <div class="border-bt"></div>
                      </div>
                    </div>
                  </b-card-text>
                  <div class="w-100 b2c-cart-info-title border hidden">
                    <span>Chọn hình thức thanh toán</span>
                  </div>
                </b-card>
                <b-card class="p-0" body-class="p-0">
                  <b-card-header>Các bước thanh toán trả góp</b-card-header>
                  <b-card-text>
                    <!--<validation-observer ref="infoValid">-->
                    <div class="col-md-12 mt-10">
                      <div class="text-note" @click="$bvModal.show('modal-dktg')">
                        Lưu ý kiểm tra điều kiện trả góp của thẻ
                      </div>
                    </div>
                    <div class="col-md-12 mt-10" @click="$bvModal.show('modal-cttg')">
                      <div class="text-note">
                        Chi tiết chương trình trả góp
                      </div>
                    </div>
                    <div class="row p-2">
                      <div class="col-md-12">
                        <label class="d-block f-bold">Bước 1: Chọn ngân hàng thanh toán <span
                            class="text-primary">*</span></label>
                        <div class="bank-type__container">
                          <template v-for="(el, index) in allSchema">
                            <div :key="`bank${index}`" v-if="!isExpand ? index < 6 : true"
                              :class="curentBank.issuerCode == el.issuerCode ? 'bank-type__item' : 'bank-type__item-noselected'"
                              @click="curentBank = el">
                              <img :src="el.logoUrl" alt='' />
                            </div>
                          </template>

                        </div>
                        <div class="more-views__container mt-10">
                          <div class="more-view__btn" @click="isExpand = !isExpand">
                            {{ !isExpand ? 'Xem thêm' : 'Thu gọn' }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-15">
                        <label class="d-block f-bold">Bước 2: Chọn loại thẻ thanh toán <span
                            class="text-primary">*</span></label>
                        <div class="bank-type__container">
                          <div v-for="(el, index) in (curentBank.schemes || [])" :key="`card-${index}`"
                            :class="cardType == el.scheme ? 'bank-type__item' : 'bank-type__item-noselected'"
                            @click="cardType = el.scheme">
                            <img v-if="el.scheme == 'MASTERCARD'" src="/assets/mastercard.png" alt='' />
                            <img v-else :src="el.logoUrl" alt='' />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 mt-15">
                        <validation-provider #default="{ errors }" rules="required">
                          <label class="d-block f-bold">Bước 3: Chọn kỳ hạn <span class="text-primary">*</span></label>
                          <v-select label="text" :class="errors.length > 0 ? 'is-invalid' : ''" v-model="period"
                            placeholder="Chọn kỳ hạn" :reduce="c => c.value" @input="onPeriodChange()"
                            :filter="fuseSearch"
                            :options="currentScheme && currentScheme.recurringInfo ? currentScheme.recurringInfo.map(el => { return { value: el.recurringNumberOfIsp, text: el.recurringNumberOfIsp + ' tháng' } }) : []">
                          </v-select>
                          <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng chọn kỳ hạn</small>
                        </validation-provider>
                      </div>
                      <div class="col-md-6 mt-15">
                        <validation-provider #default="{ errors }" rules="required">
                          <label class="d-block f-bold">Bước 4: Số tiền trả góp</label>
                          <b-form-input type="text" maxlength="35" size="sm1"
                            :value="formatNumber(selectedScheme.recurringAmount || 0) + 'đ'" disabled />
                        </validation-provider>
                      </div>
                      <div class="col-md-12 mt-10">
                        <div class="compare-package" @click="onClickComparePrice()">
                          So sánh các gói trả góp
                        </div>
                      </div>
                      <div class="col-md-12 pd-lr-45">
                        <div class="installment-info-title">
                          Thông tin trả góp
                        </div>
                      </div>
                      <div class="break-line mt-10"></div>
                      <div class="col-md-12 full-w pd-lr-45 row-price"
                        v-for="(p, stt) in cart.filter(m => m.product_type != 100)" :key="stt">
                        <div class="name-shopping">
                          <span v-html="p.product_name"></span>
                        </div>
                        <div class="price-shopping">
                          <div class="main-price">{{ p.price | number }}đ</div>
                          <div class="sub-price">{{ p.base_price | number }}đ</div>
                        </div>
                      </div>
                      <div class="col-md-12 full-w pd-lr-45 row-price"
                        v-for="(p, stt) in cart.filter(m => m.product_type == 100)" :key="p.Phone">
                        <div class="name-shopping">
                          Sim DATA ITEL
                        </div>
                        <div class="price-shopping">
                          <div class="main-price">{{ p.PackPrice | number }}đ</div>
                          <!-- <div class="sub-price">129.000đ</div> -->
                        </div>
                      </div>
                      <div class="col-md-12 full-w pd-lr-45 row-price">
                        <div class="name-shopping">
                          Phí vận chuyển
                        </div>
                        <div class="price-shopping">
                          <div class="main-price">{{ (isExpress && cart_info.shipment_type == 'home') ?
                              formatNumber(expressShipInfo.lastFee) : formatNumber(getShipmentFee())
                          }}đ</div>
                          <div class="sub-price">{{ (isExpress && cart_info.shipment_type == 'home') ?
                              formatNumber(expressShipInfo.ahamoveFee) : (isLoadingFee ? 0 :
                                formatNumber(cart_info.ghtk_fee))
                          }}đ</div>
                        </div>
                      </div>
                      <div class="col-md-12 full-w pd-lr-45 row-price">
                        <div class="name-shopping">
                          Mã khuyến mại
                        </div>
                        <div class="price-shopping">
                          <div class="main-price">-0đ</div>
                        </div>
                      </div>
                      <div class="col-md-12 full-w pd-lr-45 row-price be-color-bg">
                        <div class="name-shopping">
                          Chênh lệch với trả thẳng
                        </div>
                        <div class="price-shopping">
                          <div class="main-price">{{ formatNumber((selectedScheme.totalIspAmount || 0) -
                              (selectedScheme.amount || 0))
                          }}đ</div>
                        </div>
                      </div>
                      <div class="col-md-12 full-w pd-lr-45 row-price be-color-bg">
                        <div class="name-shopping">
                          Số tiền trả mỗi tháng
                        </div>
                        <div class="price-shopping">
                          <div class="main-price">{{ formatNumber(selectedScheme.recurringAmount || 0) }}đ</div>
                        </div>
                      </div>
                      <div class="col-md-12 full-w pd-lr-45 row-price be-color-bg">
                        <div class="name-shopping">
                          Số tháng trả góp
                        </div>
                        <div class="price-shopping">
                          <div class="main-price">{{ formatNumber(selectedScheme.recurringNumberOfIsp || 0) }} tháng
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 full-w pd-lr-45 be-color-bg">
                        <div class="break-line"></div>
                      </div>
                      <div class="col-md-12 full-w pd-lr-45 row-price be-color-bg pd-b-15">
                        <div class="name-shopping center-text__title">
                          Tổng tiền trả góp
                        </div>
                        <div class="price-shopping">
                          <div class="total-main-price">{{ formatNumber(selectedScheme.totalIspAmount || 0) }}đ</div>
                        </div>
                      </div>
                      <!-- <div class="col-md-12 full-w pd-lr-45 apply-endow-container">
                        <div class="apply-endow">
                          <img class="apply-endow__tag" src="@/assets/images/group.png"/>
                          <div class="apply-endow__content">Áp dụng ưu đãi để được giảm giá</div>
                          <img class="apply-endow__next-arr" src="@/assets/images/next-arrow.png"/>
                        </div>
                      </div> -->
                      <div class="col-md-12 full-w pd-lr-45 mt-3" v-if="!isOnIframe">
                        <div
                          v-if="!(cart.length > 0 && !!selectedScheme.recurringNumberOfIsp) || getTotalPrice() < 3000000"
                          class="cash-btn disabled">
                          Thanh toán
                        </div>
                        <div v-else class="cash-btn" @click="onPayment()">
                          Thanh toán
                        </div>
                      </div>
                      <div class="col-md-12 full-w pd-lr-45 mt-3" v-if="isOnIframe">
                        <div
                          v-if="!(cart.length > 0 && !!selectedScheme.recurringNumberOfIsp) || getTotalPrice() < 3000000"
                          class="cash-btn disabled">
                          Tạo đơn
                        </div>
                        <div v-else class="cash-btn" @click="onOrder()">
                          Tạo đơn
                        </div>
                      </div>
                      <div class="col-md-12 full-w pd-lr-45">
                        <div class="d-block bg-white text-center" style="line-height:1.5">
                          Bằng cách bấm thanh toán, bạn đồng ý với <span @click="gotoPolicy()"
                            class="text-primary text-decoration-underline">Chính sách mua hàng</span> của
                          iTel
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
    <div class="b2c-dlg-installment" v-if="isShowModalInstallment">
      <div class="installment-dlg">
        <div class="installment-dlg__header">
          Trả góp qua thẻ <span class="text-bold">{{ this.cardType }}</span>, ngân hàng <span class="text-bold">{{
              curentBank.issuerCode || ''
          }}</span>
          <img class="exit-img" src="@/assets/images/pages/exit.svg"
            @click="isShowModalInstallment = !isShowModalInstallment" />
        </div>
        <div class="installment-dlg__body">
          <table v-if="installmentTableData && installmentTableData.length > 0">
            <tr>
              <th style="text-align:left;width:30%;padding-bottom:31px">Số tháng trả góp</th>
              <th style="text-align:center;width:17.5%;font-weight:600;padding-bottom:31px"
                v-if="installmentTableData[0].threeMonth > 0">3 tháng</th>
              <th style="text-align:center;width:17.5%;font-weight:600;padding-bottom:31px"
                v-if="installmentTableData[0].sixMonth > 0">6 tháng</th>
              <th style="text-align:center;width:17.5%;font-weight:600;padding-bottom:31px"
                v-if="installmentTableData[0].nineMonth > 0">9 tháng</th>
              <th
                style="text-align:center;width:17.5%;font-weight:600;padding-bottom:31px;border-right-width: 10px;border-right-style: solid;"
                v-if="installmentTableData[0].twelveMonth > 0">12 tháng</th>
            </tr>
            <template v-for="(data, idx) in installmentTableData">
              <tr class="tr-content" :key="idx">
                <th style="text-align:left;width:30%">{{ data.text }}</th>
                <th style="text-align:center;width:17.5%;vertical-align: middle;" v-if="data.threeMonth > 0">
                  {{ formatNumber(data.threeMonth) }}đ</th>
                <th style="text-align:center;width:17.5%;vertical-align: middle;" v-if="data.sixMonth > 0">
                  {{ formatNumber(data.sixMonth) }}đ</th>
                <th style="text-align:center;width:17.5%;vertical-align: middle;" v-if="data.nineMonth > 0">
                  {{ formatNumber(data.nineMonth) }}đ</th>
                <th
                  style="text-align:center;width:17.5%;vertical-align: middle;border-right-width: 10px;border-right-style: solid;"
                  v-if="data.twelveMonth > 0">{{ formatNumber(data.twelveMonth) }}đ</th>
              </tr>
            </template>

            <tr class="tr-content-button">
              <th style="text-align:left;width:30%"></th>
              <th style="text-align:center;width:17.5%;vertical-align: middle;"
                v-if="installmentTableData[0].threeMonth > 0">
                <button class="cash-btn-th"
                  @click="period = 3; isShowModalInstallment = !isShowModalInstallment">Chọn</button>
              </th>
              <th style="text-align:center;width:17.5%;vertical-align: middle;"
                v-if="installmentTableData[0].sixMonth > 0">
                <button class="cash-btn-th"
                  @click="period = 6; isShowModalInstallment = !isShowModalInstallment">Chọn</button>
              </th>
              <th style="text-align:center;width:17.5%;vertical-align: middle;"
                v-if="installmentTableData[0].nineMonth > 0">
                <button class="cash-btn-th"
                  @click="period = 9; isShowModalInstallment = !isShowModalInstallment">Chọn</button>
              </th>
              <th
                style="text-align:center;width:17.5%;vertical-align: middle;border-right-width: 10px;border-right-style: solid;"
                v-if="installmentTableData[0].twelveMonth > 0">
                <button class="cash-btn-th"
                  @click="period = 12; isShowModalInstallment = !isShowModalInstallment">Chọn</button>
              </th>
            </tr>
          </table>
          <p class="text text-center" v-else>
            Không có thông tin trả góp
          </p>
        </div>
      </div>
    </div>
    <b-modal body-class="p-2" hide-footer id="modal-dktg" title="Điều kiện trả góp" centered>
      <b-row>
        <b-col cols="12">
          <p class="text text-left">
            Trước khi thực hiện các bước trả góp,
            Quý Khách lưu ý kiểm tra với ngân hàng về các điều kiện trả góp của thẻ hiện hành,
            liên hệ theo hotline hỗ trợ được in sau mặt thẻ.
          </p>
        </b-col>
        <b-col cols="12">
          <p class="text text-left">
            <br />
            Minh họa:
          </p>
          <div style="margin-top: 15px">
            <img style="max-width: 100%" src="@/assets/images/pages/card-example.jpg" alt='' />
          </div>
        </b-col>
        <b-col cols="12" class="border-top text-center pt-2 mt-1">
          <b-button @click="$bvModal.hide('modal-dktg')" variant="primary" size="sm" class="btn-close-modal">Đóng
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal body-class="p-2" hide-footer id="modal-cttg" title="Chương trình trả góp" size="lg">
      <b-row>
        <b-col cols="12">
          <p class="text" style="font-weight: 600">
            THÔNG TIN CHƯƠNG TRÌNH TRẢ GÓP THẺ TÍN DỤNG QUA VNPAY
          </p>
          <p class="text">
            <br />
            Chương trình áp dụng cho
            <span style="font-weight: 600">CHỦ THẺ TÍN DỤNG QUÓC TẾ</span>
            (không áp dụng cho thẻ phụ, thẻ Debit và thẻ không được phát hành tại Việt Nam) <span
              style="font-weight: 600">THỰC HIỆN THANH TOÁN TRỰC TIẾP TẠI WEBSITE VỚI HÌNH THỨC: TRẢ GÓP THẺ TÍN DỤNG
              QUA VNPAY.</span>
          </p>
          <p class="text" style="font-weight: 600;text-decoration: underline;">
            1. THÔNG TIN CHUNG:
          </p>
          <p class="text" style="margin-left: 20px">
            - Thời gian trả góp: <span style="font-weight: 600">3,6,9,12 tháng.</span>
          </p>
          <p class="text" style="margin-left: 20px">
            - Giao dịch có thể sẽ bị từ chối chuyển đổi trả góp nếu Ngân hàng xác minh được thẻ không chính chủ, thẻ hết
            hạn, không đủ hạn mức, thẻ lỗi, KH có nợ quá hạn, thanh toán cận ngày sao kê...
          </p>
          <p class="text" style="margin-left: 20px">
            - <span style="font-weight: 600">Chương trình trả góp lãi suất 0% qua Thẻ tín dụng quốc tế (Chương trình trả
              góp):</span> Là Chương trình trả góp của Tổ chức phát hành (TCPH) phối hợp thực hiện cùng VNPAY, theo đó,
            Chủ thẻ khi thanh toán bằng Thẻ tín dụng quốc tế tại Điểm chấp nhận của Đơn vị chấp nhận thanh toán (ĐVCNTT)
            được quyền đăng ký chuyển đổi giao dịch thành giao dịch trả góp lãi suất 0% (chuyển đổi trả góp).
          </p>
          <p class="text" style="margin-left: 20px">
            - <span style="font-weight: 600">Dịch vụ hỗ trợ đăng ký chuyển đổi trả góp:</span> Là dịch vụ hỗ trợ ĐVCNTT
            gửi các thông tin đăng ký trả góp của Chủ thẻ sang VNPAY để đăng ký Chương trình trả góp.
          </p>
          <p class="text" style="margin-left: 20px">
            - <span style="font-weight: 600">Thẻ tín dụng quốc tế:</span> Là phương tiện thanh toán do TCPH thẻ phát
            hành cho phép Chủ thẻ thực hiện giao dịch thanh toán tại ĐVCNTT trong phạm vi hạn mức tín dụng đã được cấp
            theo thỏa thuận với TCPH.
          </p>
          <p class="text" style="margin-left: 20px">
            - <span style="font-weight: 600">Chủ thẻ:</span> Là cá nhân hoặc tổ chức được TCPH cung cấp thẻ để sử dụng.
          </p>
          <p class="text" style="margin-left: 20px">
            - <span style="font-weight: 600">Ngày chốt sao kê:</span> Là ngày TCPH tổng hợp các giao dịch phát sinh qua
            Thẻ tín dụng quốc tế trong kỳ sao kê để gửi cho Chủ thẻ. Một kỳ sao kê thường là 30 (ba mươi) ngày.
          </p>
          <p class="text" style="margin-left: 20px">
            - <span style="font-weight: 600">VNPAY chỉ thực hiện việc chuyển tiếp yêu cầu đăng ký chuyển đổi trả góp
              sang TCPH, việc phê duyệt chuyển đổi trả góp cho giao dịch của Chủ thẻ sẽ do TCPH của Chủ thẻ quyết định
              và xử lý.</span>
          </p>
          <p class="text" style="font-weight: 600;text-decoration: underline;">
            2. QUY ĐỊNH CHUYỂN ĐỔI TRẢ GÓP:
          </p>
          <p class="text" style="margin-left: 20px">
            - Loại thẻ hỗ trợ chuyển đổi trả góp là Thẻ tín dụng quốc tế do các TCPH phát hành.
          </p>
          <p class="text" style="margin-left: 20px">
            - Giá trị đăng ký chuyển đổi trả góp tối thiểu là 3.000.000 (Ba triệu đồng Việt Nam) được áp dụng theo từng
            TCPH.
          </p>
          <p class="text" style="margin-left: 20px">
            - Với các giao dịch thực hiện trên Cổng thanh toán điện tử: Yêu cầu đăng ký chuyển đổi trả góp sẽ được VNPAY
            chuyển tiếp cho TCPH khi giao dịch thanh toán thành công.
          </p>
          <p class="text" style="margin-left: 20px">
            - Để yêu cầu hủy chuyển đổi trả góp, Chủ thẻ liên hệ trực tiếp với TCPH để được hỗ trợ.
          </p>
          <p class="text" style="margin-left: 20px">
            - Trong trường hợp Tổ chức phát hành từ chối chuyển đổi trả góp, thì đơn hàng của quý khách vẫn đã được ghi
            nhận thành công. Vì vậy, Quý khách sẽ phải trả 1 lần toàn bộ tổng giá trị đơn hàng cho Tổ chức phát hành.
          </p>
          <p class="text" style="margin-left: 20px">
            Lưu ý, Tổng giá trị đơn hàng đã bao gồm giá sản phẩm và phí chuyển đổi trả góp, nếu giao dịch đã được ngân
            hàng tiếp nhận thì không được hoàn tiền phí chuyển đổi.
          </p>
          <p class="text" style="font-weight: 600;text-decoration: underline;">
            3. QUY ĐỊNH VỀ CHẶN YÊU CẦU CHUYỂN ĐỔI TRẢ GÓP:
          </p>
          <p class="text" style="margin-left: 20px">
            Để đảm bảo quyền lợi của Chủ thẻ, Chủ thẻ không thực hiện các yêu cầu chuyển đổi trả góp sát vào ngày sao kê
            của thẻ theo quy định của từng TCPH. Quy định về việc chặn yêu cầu chuyển đổi trả góp của một số TCPH như
            sau:
          </p>
          <!-- <div style="margin-top: 15px">
            <img style="max-width: 100%" src="@/assets/images/pages/cttg.jpg" alt=''/>
          </div> -->
          <div style="margin-top: 15px;overflow-x: auto">
            <table class="table-regulations">
              <tr class="table-header">
                <th style="text-align:center;width:7%">STT</th>
                <th style="text-align:center;width:18%">Tổ chức phát hành</th>
                <th style="text-align:center;width:50%">Danh sách đầu BIN <br />(6 số đầu của thẻ)</th>
                <th style="text-align:center;width:25%">Ngày chặn<br />Yêu cầu chuyển đổi trả góp</th>
              </tr>
              <tr class="table-body">
                <td style="text-align:center;width:7%">1</td>
                <td style="text-align:left;width:18%">Ngân hàng TMCP Công Thương<br />Việt Nam (Vietinbank)</td>
                <td style="text-align:left;width:50%"><span style="background:white;">418560, 457271, 498796, 413524,
                    404185, 459817, 498795, 459818, 486863, 459804, 474813, 404184, 498794, 459805, 486862, 530581,
                    512414, 548936, 520032, 543846, 356793, 356791, 356790, 361121</span></td>
                <td style="text-align:left;width:25%">Từ ngày 01 đến hết ngày 09 hàng tháng</td>
              </tr>
              <tr class="table-body">
                <td style="text-align:center;width:7%" rowspan="3">2</td>
                <td style="text-align:left;width:18%" rowspan="3">Ngân hàng TMCP Ngoại Thương<br />Việt Nam
                  (Vietcombank)</td>
                <td style="text-align:left;width:50%">412976, 412975, 461136, 438103, 477389</td>
                <td style="text-align:left;width:25%">Từ ngày 14 đến hết ngày 20 hàng tháng</td>
              </tr>
              <tr class="table-body">
                <td style="text-align:left;width:50%">546285, 546284, 514003</td>
                <td style="text-align:left;width:25%">Từ ngày 04 đến hết ngày 10 hàng tháng</td>
              </tr>
              <tr class="table-body">
                <td style="text-align:left;width:50%">356771, 356770, 356435</td>
                <td style="text-align:left;width:25%">Từ ngày 09 đến hết ngày 15 hàng tháng</td>
              </tr>
              <tr class="table-body">
                <td style="text-align:center;width:7%">3</td>
                <td style="text-align:left;width:18%">Các TCPH/các Ngân hàng khác</td>
                <td style="text-align:left;width:50%" colspan="2">Theo quy định cụ thể của từng TCPH. Trong mọi trường
                  hợp, Khách hàng/Chủ thẻ hiểu và đồng ý rằng Chủ thẻ hoàn toàn chịu trách nhiệm trong việc liên hệ với
                  TCPH thẻ của Chủ thẻ để nắm được quy định.</td>
              </tr>
            </table>
          </div>
        </b-col>
        <b-col cols="12" class="border-top text-center pt-2 mt-1">
          <b-button @click="$bvModal.hide('modal-cttg')" variant="primary" size="sm" class="btn-close-modal">Đóng
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <form id="payment_form" action="https://sandbox.vnpayment.vn/isp-svc/payment/pay" method="post"
      style="display: none">
      <input name="ispTxnId" id="ispTxnId" type="text" />
      <input name="dataKey" id="dataKey" type="text" />
      <input name="tmnCode" id="tmnCode" type="text" />
    </form>
    <b-modal body-class="p-2" hide-footer id="modal-policy" title=" Chính sách mua hàng" centered>
      <b-row>
        <b-col cols="12">
          <p class="text text-left policy-popup" v-for="(p, stt) in listPolicys" :key="stt">{{ (stt + 1) }} <span
              v-html="p"></span></p>
          <p class="text text-left policy-popup">Xin chân thành cảm ơn.</p>
        </b-col>
        <b-col cols="12" class="border-top text-center pt-2 mt-1">
          <b-button @click="$bvModal.hide('modal-policy')" variant="primary" size="sm" class="btn-close-modal">Đóng
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, email } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import Select2 from "v-select2-component";
import { OriginType, ProvinceIdConst } from '@/libs/acl/config';

import {
  BCardHeader,
  BCard,
  VBTooltip,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BPagination,
  BCardText,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  TabsPlugin,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { WebCam } from "vue-web-cam";
import Vue from "vue";
import vSelect from "vue-select";
import StarRating from "vue-star-rating";
import Fuse from "fuse.js";
import { $ctx } from "@themeConfig";
import { v4 as uuidv4 } from "uuid";
import { formatNumber } from "@core/utils/utils";
import axios from 'axios'


let debounceHandle = null;

Vue.component("v-select", vSelect);

extend("msisdn", (value) => {
  const first4 = value.slice(0, 4);
  const first3 = value.slice(0, 3);
  if (first4 !== "8487" && first3 !== "087") {
    return false;
  } else if (
    (first4 === "8487" && value.length !== 11) ||
    (first3 === "087" && value.length !== 10)
  ) {
    return false;
  }
  return true;
});

extend("num", (value) => {
  return !/\D/.test(value);
});
extend("space", (value) => {
  return value.indexOf(" ") > 0;
});
extend("min0", (value) => {
  return value > 0;
});
extend("cccd", (value) => {
  return value && value.length >= 9 && value.length <= 12
})

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    BCardHeader,
    Select2,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    FeatherIcon,
    BFormCheckbox,
    BCardText,
    BPagination,
    "vue-web-cam": WebCam,
    VBTooltip,
    StarRating,
    Fuse,
    BImg,
    TabsPlugin,
    BTabs,
    BTab,
  },
  data() {
    return {
      isExpand: false,
      period: null,
      isShowModalInstallment: false,
      totalMoney: 3567000,
      allSchema: [],
      selectedScheme: {},
      cardType: "",
      curentBank: {},
      numberExceptCollapse: true,
      storeCollapse: true,
      step: 1,
      NumOfPhone: 1,
      deviceId: null,
      isLoading: false,
      isLoadingFee: false,
      currentPhone: "",
      SearchString: "",
      Slug: "",
      required,
      min,
      email,
      totalRecords: 0,
      rows: [],
      paymentMethod: "VNPAY",
      VnPayResponse: null,
      MoMoResponse: null,
      cart_info: {
        shipment_type: "home",
        data_hasSIM: true,
        ghtk_fee: 0,
        _track: "T_" + Math.random().toString(36).slice(2),
      },
      cart: [],
      DataPackages: [],
      years: [],
      Cities: [],
      Districts: [],
      Wards: [],
      Hamlets: [],
      CityId: 0,
      DistrictId: 0,
      WardId: 0,
      HamletId: 0,
      VnPayResponse: {},
      Stores: [],
      vnPayErrors: [
        { id: "00", text: "Giao dịch thành công" },
        {
          id: "07",
          text: "Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường).",
        },
        {
          id: "09",
          text: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.",
        },
        {
          id: "10",
          text: "Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần",
        },
        {
          id: "11",
          text: "Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.",
        },
        {
          id: "12",
          text: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng bị khóa.",
        },
        {
          id: "13",
          text: "Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.",
        },
        {
          id: "24",
          text: "Giao dịch không thành công do: Khách hàng hủy giao dịch",
        },
        {
          id: "51",
          text: "Giao dịch không thành công do: Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.",
        },
        {
          id: "65",
          text: "Giao dịch không thành công do: Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày.",
        },
        { id: "75", text: "Ngân hàng thanh toán đang bảo trì." },
        {
          id: "79",
          text: "Giao dịch không thành công do: KH nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch",
        },
        {
          id: "99",
          text: "Lỗi thanh toán ngân hàng, quý khách vui lòng thử lại",
        },
      ],
      OrderInfo: {},
      isExpress: false,
      isFast: true,
      hasExpressShip: false,
      expressShipInfo: {
        ahamoveFee: 0,
        lastFee: 0,
        deleveryTime: "",
      },
      installmentTableData: [
      ],
      currentScheme: [],
      listPolicys: []
    };
  },
  watch: {
    $route(to, from) {
      this.step = 1;
      this.cart_info._track = "T_" + Math.random().toString(36).slice(2);
    },
    cart_info: {
      handler: function (o, n) {

      },
      deep: true,
    },
    CityId: function (i, v) {
      this.DistrictId = 0;
      if (this.CityId > 0) {
        this.loading(true);
        useJwt
          .post("getMasterData/GHTK_Districts", {
            columnFilters: { CityId: this.CityId },
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Districts = response.data.result;
            this.loading(false);
          });
      }
    },
    DistrictId: function (i, v) {
      this.WardId = 0;
      this.getShippingFee();
      if (this.DistrictId > 0) {
        this.loading(true);
        useJwt
          .post("getMasterData/GHTK_Wards", {
            columnFilters: { DistrictId: this.DistrictId },
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Wards = response.data.result;
            this.loading(false);
          });
      }
    },
    WardId: async function (i, v) {
      this.HamletId = 0;
      if (this.WardId > 0) {
        this.loading(true);
        await useJwt.post('getMasterData/GHTK_Hamlets', { columnFilters: { WardId: this.WardId }, sort: [], page: 1, pageSize: 1000, lang: this.$l }).then(response => {
          this.Hamlets = response.data.result;
          this.loading(false);
        })
      }
      this.isLoadingFee = true;
      this.getShippingFee();
      await useJwt
        .get(
          "ghtk/fee?province=" +
          this.CityId +
          "&district=" +
          this.DistrictId +
          "&ward=" +
          this.WardId
        )
        .then((response) => {
          this.cart_info = {
            ...this.cart_info,
            ghtk_fee: response.data.result.fee.fee,
          };
          this.isLoadingFee = false;
        })
        .catch((err) => {
          this.cart_info = {
            ...this.cart_info,
            ghtk_fee: 0,
          };
          this.isLoadingFee = false;
        });
    },
    "cart_info.fullName": {
      deep: true,
      handler(value) {
        this.handleGetShippingFee();
      },
    },
    "cart_info.addr": {
      deep: true,
      handler(value) {
        this.handleGetShippingFee();
      },
    },
    "cart_info.ContactPhone": {
      deep: true,
      handler(value) {
        this.handleGetShippingFee();
      },
    },
    cart: {
      deep: true,
      handler() {
        this.getAllSchema()
      }
    },
    curentBank: {
      deep: true,
      handler() {
        this.onCurrentbackChange()
      }
    },
    cardType: {
      deep: true,
      handler() {
        this.onCardTypeChange()
      }
    },
    period: {
      deep: true,
      handler() {
        this.onPeriodChange()
      }
    },
    step: function (newValue, oldValue) {
      localStorage.userTrackStep = newValue
    }
  },
  created() {
    this.years.push({ id: 0, text: "Tất cả ngày sinh" });
    for (var i = 0; i < 50; i++) {
      this.years.push({ id: i + 1970, text: i + 1970 });
    }
  },
  computed: {
    isOnIframe() {
      return this.$store.getters[`app/isOnIframe`]
    }
  },
  mounted() {
    //Lấy thông tin cart
    this.cart = JSON.parse(localStorage.getItem("cart")) || [];
    //lấy tỉnh thành phố
    this.fetch();
    console.log(this.cart)
    if (localStorage.trackId == undefined) {
      localStorage.trackId = Math.random().toString(36).slice(2);
    }
    this.tracking(localStorage.trackId, 3);
    localStorage.userTrackStep = 3
    if (this.$route.params.step == "hoan-thanh") {
      this.step = 4;
      this.cart = [];
      this.tracking(localStorage.trackId, this.step);
      //Xoá giỏ hàng trước đó
      localStorage.removeItem("cart");

      // payment with MoMo
      if (this.$route.query.orderType == "momo_wallet") {
        this.MoMoResponse = this.$route.query;
        // if MOMO code = 0 => order sucress
        if (this.MoMoResponse?.resultCode == "0") {
          window.insider_object.page = {
            type: "Confirmation",
          };
        }
        useJwt
          .post("momo-update", this.$route.query)
          .then((response) => {
            if (response.data.result.length > 0) {
              return (this.OrderInfo = response.data.result[0]);
            }
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
              "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
              { icon: true, closeButton: "button" }
            );
          });
        return;
      }
      this.VnPayResponse = this.$route.query;
      //  change type insider
      /*if (this.VnPayResponse.vnp_ResponseCode == "00") {
        window.insider_object.page = {
          type: "Confirmation",
        };
      }*/
      useJwt
        .post("vnpay-isp/update", this.$route.query)
        .then((response) => {
          console.log(response.data.result);
          if (response.data.result.length > 0) {
            this.OrderInfo = response.data.result[0];
          }
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    } else {
      this.step = 1;
    }
  },
  methods: {
    onCurrentbackChange() {
      if (this.curentBank.issuerCode) {
        if (this.curentBank.schemes[0]) {
          this.cardType = this.curentBank.schemes[0].scheme
          this.currentScheme = this.curentBank.schemes.find(el => el.scheme == this.cardType)
          this.onPeriodChange()
        } else {
          this.cardType = ''
        }
      } else {
        this.cardType = ''
      }
    },
    onCardTypeChange() {
      if (this.cardType) {
        this.currentScheme = this.curentBank.schemes.find(el => el.scheme == this.cardType)
        this.onPeriodChange()
      } else {
        this.currentScheme = {}
      }
    },
    getAllSchema() {
      useJwt.get(`vnpay-isp/installment-info/${this.getTotalPrice()}`).then(rs => {
        if (rs.data.result) {
          let schemaData = rs.data.result || []
          schemaData = schemaData.map(el => {
            el.schemes = el.schemes.map(sche => {
              sche.recurringInfo = sche.recurringInfo.map(re => {
                return {
                  ...re,
                  amount: parseInt(re.amount / 100),
                  recurringAmount: parseInt(re.recurringAmount / 100),
                  feeAmount: parseInt(re.feeAmount / 100),
                  totalIspAmount: parseInt(re.totalIspAmount / 100)
                }
              })
              return sche
            })
            return el
          })
          this.allSchema = schemaData
          if (this.curentBank.issuerCode) {
            this.curentBank = this.allSchema.find(el => el.issuerCode == this.curentBank.issuerCode) || {}
          }
        }
      })
    },
    onPeriodChange() {
      if (this.period && this.currentScheme) {
        this.onSelectScheme(this.period)
      }
    },
    onSelectScheme(index) {
      this.isShowModalInstallment = false
      this.selectedScheme = this.currentScheme?.recurringInfo?.find(el => el.recurringNumberOfIsp == index) || {}
    },
    onClickComparePrice() {
      if (this.allSchema) {
        const bank = this.allSchema.find(el => el.issuerCode == (this.curentBank.issuerCode || ''))
        if (bank) {
          const scheme = bank.schemes.find(el => el.scheme == this.cardType)

          if (scheme) {
            this.currentScheme = scheme
            const three = scheme?.recurringInfo.find(el => el.recurringNumberOfIsp == 3) || {}
            const six = scheme?.recurringInfo.find(el => el.recurringNumberOfIsp == 6) || {}
            const nine = scheme?.recurringInfo.find(el => el.recurringNumberOfIsp == 9) || {}
            const twelve = scheme?.recurringInfo.find(el => el.recurringNumberOfIsp == 12) || {}

            const newData = [{
              text: 'Giá sản phẩm',
              threeMonth: three?.amount || 0,
              sixMonth: six?.amount || 0,
              nineMonth: nine?.amount || 0,
              twelveMonth: twelve?.amount || 0,
            },
            {
              text: 'Góp mỗi tháng',
              threeMonth: three?.recurringAmount || 0,
              sixMonth: six?.recurringAmount || 0,
              nineMonth: nine?.recurringAmount || 0,
              twelveMonth: twelve?.recurringAmount || 0,
            },
            {
              text: 'Tổng tiền trả góp',
              threeMonth: three?.totalIspAmount || 0,
              sixMonth: six?.totalIspAmount || 0,
              nineMonth: nine?.totalIspAmount || 0,
              twelveMonth: twelve?.totalIspAmount || 0,
            },
            {
              text: 'Chênh lệch với mua trả thẳng',
              threeMonth: (three?.totalIspAmount || 0) - (three?.amount || 0),
              sixMonth: (six?.totalIspAmount || 0) - (six?.amount || 0),
              nineMonth: (nine?.totalIspAmount || 0) - (nine?.amount || 0),
              twelveMonth: (twelve?.totalIspAmount || 0) - (twelve?.amount || 0),
            }]

            this.installmentTableData = [...newData]
            this.isShowModalInstallment = true
          } else {
            this.currentScheme = []
            this.installmentTableData = []
            this.isShowModalInstallment = true
          }
        } else {
          this.currentScheme = []
          this.installmentTableData = []
          this.isShowModalInstallment = true
        }
      }
    },
    async fetch() {
      await useJwt
        .post("getMasterData/GHTK_Cities", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          this.Cities = response.data.result;
        })
        .catch((err) => {
          this.$toast.error(
            "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    removeProduct(p) {
      this.loading(true)
      if (p.product_type == 100) {
        this.cart = this.cart.filter(item => item.Phone != p.Phone)
      } else {
        /*To do sửa lại chỗ này*/
        this.cart = this.cart.filter(item => item.uuid != p.uuid)
      }
      localStorage.setItem("cart", JSON.stringify(this.cart));
      this.loading(false)
      let productPromotion = this.cart.filter(function (item) { return item.categories && item.categories[0] && (item.categories[0].slug == 'dien-thoai' || item.categories[0].slug == 'may-tinh-bang') });
      if (productPromotion.length == 0) {
        this.cart = this.cart.filter(item => item.product_type != 100);
      }
      if (this.cart.length == 0 || this.cart.find(item => item.product_type != 100) == undefined) {
        localStorage.removeItem("cart");
        // this.$router.push({ name: "home" });
      }
    },
    changeAmount(p, amount_) {
      var p = this.cart.find(item => item.uuid == p.uuid);
      if (p.amount + amount_ == 0) {
        this.removeProduct(p);
      } else {
        p.amount = p.amount + amount_;
      }
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    formatNumber(x) {
      return formatNumber(x);
    },
    handleGetShippingFee() {
      if (debounceHandle) {
        clearTimeout(debounceHandle);
      }
      debounceHandle = setTimeout(() => {
        this.getShippingFee();
      }, 2000);
    },
    f087(value) {
      if (value) {
        return value.substr(0, 4) + " " + value.substr(4);
      } else {
        return value
      }
    },
    redirectToSupport() {
      this.$router.push({
        path: this.$t("Route.Support") + this.$t("Route.ContactUs"),
      });
    },
    tracking(id, step) {
      const parsedParams = this.$route.query;
      this.$route.hash
        .split("&")
        .map((part) => part.replace(/^#/, ""))
        .forEach((param) => {
          const parts = param.split("=");
          parsedParams[parts[0]] = parts[1];
        });
      useJwt
        .post("tracking/" + id + "/" + step, {
          SimData: true,
          cart_info: this.cart_info,
          card: this.cart,
          query: parsedParams,
        })
        .then((response) => {
          //console.log(response.data.result)
        });
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["text", "text1"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    getShippingFee() {
      if (
        !(this.CityId && this.DistrictId && this.WardId && this.cart_info.addr)
      ) {
        this.expressShipInfo = {
          ahamoveFee: 0,
          lastFee: 0,
          deleveryTime: "",
        };
        return;
      }
      let fullAddress = this.cart_info.addr || "";
      if (this.WardId && this.Wards.find((el) => el.id == this.WardId)) {
        fullAddress += `, ${this.Wards.find((el) => el.id == this.WardId).text
          }`;
      }
      if (
        this.DistrictId &&
        this.Districts.find((el) => el.id == this.DistrictId)
      ) {
        fullAddress += `, ${this.Districts.find((el) => el.id == this.DistrictId).text
          }`;
      }
      if (this.CityId && this.Cities.find((el) => el.id == this.CityId)) {
        fullAddress += `, ${this.Cities.find((el) => el.id == this.CityId).text
          }`;
      }
      useJwt
        .post("get_fee_and_delivery_time", {
          city_id: this.CityId,
          addr: fullAddress,
          FullName: this.cart_info.fullName,
          ContactPhone: this.cart_info.ContactPhone,
        })
        .then((response) => {
          this.expressShipInfo = {
            ahamoveFee: response?.data?.result?.ahamove_fee || 0,
            lastFee: response?.data?.result?.last_fee || 0,
            deleveryTime: response?.data?.result?.delivery_time || "",
          };
        })
        .catch((err) => {
          this.$toast.error(
            err?.response?.data?.message ||
            "Có lỗi trong khi thực hiện tra cứu, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );

          this.expressShipInfo = {
            ahamoveFee: 0,
            lastFee: 0,
            deleveryTime: "",
          };
        });
    },
    onCityChange() {
      this.loading(true);
      this.getShippingFee();
      useJwt
        .get(`is_fast/${this.CityId}`)
        .then((response) => {
          this.hasExpressShip = !!response?.data?.result;
          if (!this.hasExpressShip) {
            this.isExpress = false;
            this.isFast = true;
          }
          this.loading(false);
        })
        .catch((err) => {
          this.hasExpressShip = false;
          this.isExpress = false;
          this.isFast = true;
          this.loading(false);
        });
    },
    onClickExpress() {
      if (!this.isExpress) {
        this.isExpress = true;
        this.isFast = false;
      }
    },
    onClickFast() {
      if (!this.isFast) {
        this.isFast = true;
        this.isExpress = false;
      }
    },
    change_shipment(e) {
      this.isLoading = true;
      this.cart_info.shipment_type = e;
      this.storeCollapse = this.cart_info.shipment_type == "home";
      this.isLoading = false;
    },
    getShipmentFee() {
      let carBaseus = this.cart.filter(function (item) {
        return item.origin_type == OriginType.BASUES;
      });
      if (carBaseus != null && carBaseus.length > 0 && this.CityId > 0) {
        //fix Baseus: nếu là HCM giư lại 20k với  còn với các tỉnh khác 30k
        if (this.CityId == ProvinceIdConst.HCM) return 20000;
        else return 30000;
      }
      else if (
        this.cart_info.shipment_type == "home" && this.cart.find(item => item.product_type == 100) != undefined
      ) {
        //return 15000; giá ship đã có trong sp
        return 0;
      } else {
        return 0;
      }
    },
    getShipmentBaseus() {
      let carBaseus = this.cart.filter(function (item) {
        return item.origin_type == OriginType.BASUES;
      });
      if (carBaseus != null && carBaseus.length > 0 && this.CityId > 0) {
        //fix Baseus: nếu là HCM giư lại 20k với  còn với các tỉnh khác 30k
        if (this.CityId == ProvinceIdConst.HCM) return 20000;
        else return 30000;
      }
      else
        return 0;
    },
    getTotalPrice() {
      var n = 0;
      this.cart.forEach((i) => {
        if (i.product_type != 100) {
          n += i.amount * i.price;
        } else {
          n += i.PackPrice;
        }
      });
      return (
        n +
        (this.isExpress && this.cart_info.shipment_type == "home"
          ? this.expressShipInfo.lastFee
          : this.getShipmentFee())
      );
    },

    chonThanhToanLai() {
      if (this.$route.query.orderType == "momo_wallet") {
        return this.thanhtoanlaiMoMo();
      } else {
        return this.thanhtoanlai();
      }
    },

    // Thanh toán lại bằng Ví MOMO
    thanhtoanlaiMoMo() {
      const urlPay = "get-momo-url-again/" + this.$route.query.orderId;
      this.loading(true);
      useJwt
        .get(urlPay)
        .then((response) => {
          if (response.data.result.url) {
            this.tracking(localStorage.trackId, "11");
            localStorage.userTrackStep = 11
            document.location = response.data.result.url;
            return;
          }
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    // Thanh toán lại bằng Ví VNPAY
    thanhtoanlai() {
      const urlPay = "get-vnpay-url-again/" + this.VnPayResponse.vnp_TxnRef;
      this.loading(true);
      useJwt
        .get(urlPay)
        .then((response) => {
          var urlParams = new URLSearchParams(response.data.result.url);
          this.cart_info.vnp_TxnRef = urlParams.get("vnp_TxnRef");
          this.cart_info.old_vnp_TxnRef = this.VnPayResponse.vnp_TxnRef;
          //console.log(response.data.result);
          this.tracking(localStorage.trackId, "11");
          localStorage.userTrackStep = 11
          console.log(this.cart_info);
          document.location = response.data.result.url;
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    selectStore(id) {
      this.isLoading = true;
      this.cart_info.StoreId = id.Id;
      this.isLoading = false;
      this.storeCollapse = true;
    },

    onPayment() {

      switch (this.paymentMethod) {
        case "VNPAY":
          return this.thanhtoan();
        case "MOMO":
          return this.thanhtoanMOMO();
        case "ZALO":
          return this.thanhtoanZALO();
      }
    },

    async thanhtoanZALO() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
          "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
          { icon: true, closeButton: "button" }
        );
        return;
      }
      console.log(this.cart_info);
      //return;
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
          this.cart_info.shipment_type == "itel" &&
          (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
  
      this.loading(true);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      //bfd nếu là baseus thì remove description, với thông tin bên woo
      var listItemBaseus = [];
      this.cart.forEach((x) => {
        if (x.origin_type == OriginType.BASUES) {
          x.body_html = "";
          x.variants.forEach((variant) => {
            variant.wooProduct = {};
          });
          //lưuư thêm thông tin sản phẩm đang dc chọn
          if (x.variants[x.selectIndex] && x.variants[x.selectIndex].wooProduct) {
            x.variants[x.selectIndex].wooProduct = {};
          }
          var selected = { 'value': x.variants[x.selectIndex].title };
          selected['priceWoo'] = x.variants[x.selectIndex].priceWoo;
          x.variants[0].selected = selected;
          if (x.variant_id) {
            listItemBaseus.push({
              quantity: x.amount,
              title: x.title,
              variant_id: x.variant_id,
              variant_title: x.variant_title,
              price: x.variants[x.selectIndex].priceWoo, 
              vendor: x.vendor,
              type: "Khác",
            });
          }
        }
      });

      //tạo đơn baseus -> nếu thành công gán thêm ref_id để lưu và theo dõi + confirm
      if (listItemBaseus && listItemBaseus.length > 0) {
        var result = await this.createOrderBaseus(listItemBaseus);

        if (
          result == null ||
          result.status != 200 ||
          !result.data ||
          !result.data.result
        ) {
          this.$toast.warning("Có lỗi tạo đơn hàng Baseus. Xin vui lòng thử lại sau", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
        if (result.data && result.data.result) {
          var oderBaseus = JSON.parse(result.data.result);
          this.cart.forEach((x) => {
            if (x.origin_type == OriginType.BASUES) {
              x.ref_id = oderBaseus.order.id;
            }
          });
        }
      }

      useJwt
        .post("get-momo-url", {
          Phone: this.cart[0].Phone,
          TotalPrice: this.getTotalPrice(),
          ShipmentFee: this.getShipmentFee(),
          vnp_Bill_Mobile: this.cart_info.ContactPhone,
          fullName: this.cart_info.fullName,
          cart_info: this.cart_info,
          cart: this.cart,
        })
        .then((response) => {
          this.tracking(localStorage.trackId, "10");
          localStorage.userTrackStep = 10
          document.location = response.data.result.url;
          this.loading(false);
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status == 400) {
            this.$bvModal.show("modal-dup");
          } else {
            this.$toast.error(
              "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
              { icon: true, closeButton: "button" }
            );
          }
          this.loading(false);
        });
    },
    async thanhtoanMOMO() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
          "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
          { icon: true, closeButton: "button" }
        );
        return;
      }
      console.log(this.cart_info);
      //return;
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
          this.cart_info.shipment_type == "itel" &&
          (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      this.loading(true);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      //bfd nếu là baseus thì remove description, với thông tin bên woo
      var listItemBaseus = [];
      this.cart.forEach((x) => {
        if (x.origin_type == OriginType.BASUES) {
          x.body_html = "";
          x.variants.forEach((variant) => {
            variant.wooProduct = {};
          });
          //lưuư thêm thông tin sản phẩm đang dc chọn
          if (x.variants[x.selectIndex] && x.variants[x.selectIndex].wooProduct) {
            x.variants[x.selectIndex].wooProduct = {};
          }
          var selected = { 'value': x.variants[x.selectIndex].title };
          selected['priceWoo'] = x.variants[x.selectIndex].priceWoo;
          x.variants[0].selected = selected;

          if (x.variant_id) {
            listItemBaseus.push({
              quantity: x.amount,
              title: x.title,
              variant_id: x.variant_id,
              variant_title: x.variant_title,
              vendor: x.vendor, 
              price: x.variants[x.selectIndex].priceWoo,
              type: "Khác",
            });
          }
        }
      });

      //tạo đơn baseus -> nếu thành công gán thêm ref_id để lưu và theo dõi + confirm
      if (listItemBaseus && listItemBaseus.length > 0) {
        var result = await this.createOrderBaseus(listItemBaseus);
        console.log(result);
        if (
          result == null ||
          result.status != 200 ||
          !result.data ||
          !result.data.result
        ) {
          this.$toast.warning("Có lỗi tạo đơn hàng Baseus. Xin vui lòng thử lại sau", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
        if (result.data && result.data.result) {
          var oderBaseus = JSON.parse(result.data.result);
          this.cart.forEach((x) => {
            if (x.origin_type == OriginType.BASUES) {
              x.ref_id = oderBaseus.order.id;
            }
          });
        }
      }

      useJwt
        .post("get-momo-url", {
          Phone: this.cart[0].Phone,
          TotalPrice: this.getTotalPrice(),
          ShipmentFee: (this.isExpress && this.cart_info.shipment_type == 'home') ? this.expressShipInfo.lastFee : this.getShipmentFee(),
          vnp_Bill_Mobile: this.cart_info.ContactPhone,
          fullName: this.cart_info.fullName,
          cart_info: this.cart_info,
          cart: this.cart,
        })
        .then((response) => {
          this.tracking(localStorage.trackId, "10");
          localStorage.userTrackStep = 10
          document.location = response.data.result.url;
          this.loading(false);
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status == 400) {
            this.$bvModal.show("modal-dup");
          } else {
            this.$toast.error(
              "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
              { icon: true, closeButton: "button" }
            );
          }
          this.loading(false);
        });
    },
    onOrder() {
      parent.postMessage({ type: 'onOrder' }, '*')
    },

    async thanhtoan() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });

      if (!validate) {
        this.$toast.warning(
          "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email",
          { icon: true, closeButton: "button" }
        );
        return;
      }

      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
          this.cart_info.shipment_type == "itel" &&
          (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsSimData = 1;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      this.loading(true);

      const ispInfo = {
        "issuerCode": this.curentBank.issuerCode || '',
        "scheme": this.cardType,
        "recurringNumberOfIsp": this.selectedScheme.recurringNumberOfIsp || 0,
        "recurringFrequency": this.selectedScheme.recurringFrequency || 0,
        "amount": (this.selectedScheme.amount || 0) * 100,
        "recurringAmount": (this.selectedScheme.recurringAmount || 0) * 100,
        "totalIspAmount": (this.selectedScheme.totalIspAmount || 0) * 100,
        "feeAmount": (this.selectedScheme.feeAmount || 0) * 100,
        "currCode": this.selectedScheme.currCode || 0,
      }
      //bfd nếu là baseus thì remove description, với thông tin bên woo
      var listItemBaseus = [];
      this.cart.forEach((x) => {
        if (x.origin_type == OriginType.BASUES) {
          x.body_html = "";
          x.variants.forEach((variant) => {
            variant.wooProduct = {};
          });
          //lưuư thêm thông tin sản phẩm đang dc chọn
          if (x.variants[x.selectIndex] && x.variants[x.selectIndex].wooProduct) {
            x.variants[x.selectIndex].wooProduct = {};
          }
          var selected = { 'value': x.variants[x.selectIndex].title };
          selected['priceWoo'] = x.variants[x.selectIndex].priceWoo;
          x.variants[0].selected = selected;
          if (x.variant_id) {
            listItemBaseus.push({
              quantity: x.amount,
              title: x.title,
              variant_id: x.variant_id,
              variant_title: x.variant_title,
              vendor: x.vendor, 
              price: x.variants[x.selectIndex].priceWoo,
              type: "Khác",
            });
          }
        }
      });

      //tạo đơn baseus -> nếu thành công gán thêm ref_id để lưu và theo dõi + confirm
      if (listItemBaseus && listItemBaseus.length > 0) {
        var result = await this.createOrderBaseus(listItemBaseus);
        console.log(result);
        if (
          result == null ||
          result.status != 200 ||
          !result.data ||
          !result.data.result
        ) {
          this.$toast.warning("Có lỗi tạo đơn hàng Baseus. Xin vui lòng thử lại sau", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
        if (result.data && result.data.result) {
          var oderBaseus = JSON.parse(result.data.result);
          this.cart.forEach((x) => {
            if (x.origin_type == OriginType.BASUES) {
              x.ref_id = oderBaseus.order.id;
            }
          });
        }
      }
      const ispData = {
        Phone: this.cart_info.ContactPhone,
        TotalPrice: this.getTotalPrice(),
        ShipmentFee: (this.isExpress && this.cart_info.shipment_type == 'home') ? this.expressShipInfo.lastFee : this.getShipmentFee(),
        vnp_Bill_Mobile: this.cart_info.ContactPhone,
        fullName: this.cart_info.fullName,
        cart_info: this.cart_info,
        cart: this.cart,
        transactionType: 'DEVICE',
        ispInfo: ispInfo
      }

      localStorage.setItem("ispData", JSON.stringify(ispData))

      useJwt.post("vnpay-isp/init", ispData)
        .then((response) => {
          // var urlParams = new URLSearchParams(response.data.result.url);
          // this.cart_info.vnp_TxnRef = urlParams.get("vnp_TxnRef");
          // this.tracking(localStorage.trackId, "10");

          if (response.data.result) {

            const requestUrl = response?.data?.result.paymentUrl
            const ispTxnId = response?.data?.result.ispTxnId
            const dataKey = response?.data?.result.dataKey
            const tmnCode = response?.data?.result.tmnCode

            document.getElementById("ispTxnId").setAttribute("value", ispTxnId);
            document.getElementById("dataKey").setAttribute("value", dataKey);
            document.getElementById("tmnCode").setAttribute("value", tmnCode);
            document.getElementById("payment_form").setAttribute('action', requestUrl)
            document.getElementById("payment_form").submit();
          }


          // document.location = response.data.result.url;
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    Img(pic) {
      return images[pic];
    },
    preventEnterTextCharacter(event) {
      const regex = /[a-zA-Z]/;
      var key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );
      if (regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    gotoPolicy() {
      if (this.cart && this.cart.length == 1) {
        if (this.cart[0].origin_type == OriginType.BASUES) {
          window.open("https://baseus.vn/pages/chinh-sach-bao-hanh", "_blank");
        }
        else if (this.cart[0].origin_type == OriginType.OPPO) {
          window.open("https://itel.vn/tin-tuc/tin-dich-vu/chinh-sach-mua-hang-va-bao-hanh-san-pham-oppo-tren-website-itel.vn", "_blank");

        }
        else {
          window.open("https://itel.vn/thong-tin/mua-sim-so-itel", "_blank");
        }
      }
      else if (this.cart.length > 1) {
        var isSim = false;
        var isBaseus = false;
        var isOppo = false;
        this.listPolicys = [];
        this.cart.forEach(function (item) {
          if (item.product_type == 100) {
            isSim = true;
          }
          if (item.origin_type == OriginType.BASUES) {
            isBaseus = true;
          }
          else if (item.origin_type == OriginType.OPPO) {
            isOppo = true;
          }
        });
        if (isSim) {
          this.listPolicys.push('. Chính sách mua SIM số iTel: <br/>- Bao gồm các chính sách về giá; chính sách sản phẩm cho SIM thường, SIM số cam kết (Số VIP), eSIM; chính sách lưu trữ thông tin. Chi tiết vui lòng xem <a href="https://itel.vn/thong-tin/mua-sim-so-itel"class="text-primary text-decoration-underline" target="_blank">TẠI ĐÂY </a>');
        }
        if (isBaseus) {
          this.listPolicys.push('. Chính sách mua sản phẩm Baseus: <br/>- Bao gồm các quy định về thời gian bảo hành; điều kiện để được bảo hành;  chính sách bảo hành; trung tâm bảo hành trên cả nước;… Chi tiết vui lòng xem <a href="https://baseus.vn/pages/chinh-sach-bao-hanh" class="text-primary text-decoration-underline" target="_blank">TẠI ĐÂY </a>');
        }
        if (isOppo) {
          this.listPolicys.push('. Chính sách mua sản phẩm OPPO: <br/> - Bao gồm các quy định về thời gian bảo hành; điều kiện để được bảo hành;  một số lưu ý quan trọng; dịch vụ sửa chữa ngoài bảo hành… Chi tiết vui lòng xem <a href="https://itel.vn/tin-tuc/tin-dich-vu/chinh-sach-mua-hang-va-bao-hanh-san-pham-oppo-tren-website-itel.vn" class="text-primary text-decoration-underline" target="_blank">TẠI ĐÂY </a>');
        }
        this.$bvModal.show("modal-policy");
      }
    },
    /**
    * tạo đơn baseus
    * @param {tao} listItem
    */
    async createOrderBaseus(listItem) {
      try {
        var totalAmount = 0;
        listItem.forEach(function (item) {
          totalAmount += (item.quantity ? item.quantity : 0) * (item.price ? item.price : 0);
        });
        var address=  this.Wards.find((el) => el.id == this.WardId).text+', '+this.Districts.find((el) => el.id == this.DistrictId)
                .text+', '+this.Cities.find((el) => el.id == this.CityId).text;
        if(this.HamletId>0){
          address = this.Hamlets.find((el) => el.id == this.HamletId).text+', ' +address;
        }
        if(this.cart_info.addr&&this.cart_info.addr.length>0){
          address =  this.cart_info.addr +', '+address;
        }
        return await useJwt.post("/baseus/createdOrder", {
          order: {
            email: this.cart_info.email,
            phone: this.cart_info.ContactPhone,
            first_name: this.cart_info.fullName,
            total_spent: totalAmount ,
            fulfillment_status: "notfulfilled",
            note: "Đơn hàng từ iTel",
            // source:'App ITEL',
            line_items: listItem,
            shipping_address: {
              address1: address,
              city: this.Cities.find((el) => el.id == this.CityId).text,
              country: "Vietnam",
              last_name: this.cart_info.fullName,
              phone: this.cart_info.ContactPhone,
              province: this.Cities.find((el) => el.id == this.CityId).text,
              name: this.cart_info.fullName,
              province_code: this.Cities.find((el) => el.id == this.CityId)
                .code,
              country_code: "VN",
              default: true,
              district: this.Districts.find((el) => el.id == this.DistrictId)
                .text,
              district_code: this.Districts.find(
                (el) => el.id == this.DistrictId
              ).code,
              ward: this.Wards.find((el) => el.id == this.WardId).text,
              ward_code: this.Wards.find((el) => el.id == this.WardId).code,
            },
            shipping_lines: [
              {
                price: this.getShipmentFee()
              }
            ]
          },
        });
      } catch (ex) {
        return null;
      }
    }
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 1199px) {
  .b2c-o-h {
    padding: 0px 20px
  }

  .b2c-pc-pack-content .b2c-pc-info,
  .b2c-pc-payment {
    margin-top: 10px !important;
  }

  .items-container {
    padding-right: 0 !important;
  }

  .huge-text {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 755px) {
  .cart .info {
    width: 100% !important;
  }

  .cart .info {
    padding-top: 10px;
  }

  .item-info {
    padding-left: 0;
    padding-top: 20px !important;
  }

  .b2c-pc-pack-content .cart {
    height: 405px !important;
  }

  .cart .image {
    width: 100% !important;
  }

  .cart .image img.image-product {
    object-fit: contain !important;
  }
}

.arrow-icon-end {
  background-image: url("../assets/images/icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: 10px 18px;
  background-position: 95% 50%;
  padding: 11.5px !important;
}

.policy-popup {
  line-height: 30px;
}

.text-decoration-underline {
  text-decoration: underline;
  cursor: pointer;
}

.check-box-policy {
  display: flex;
  align-items: center;

  .custom-control-label {
    padding-left: 15px !important;
  }
}

.disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.6;
}

.shipping-nav {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 !important;
}

.fs-sp .shipping-nav {
  overflow-y: visible;
  overflow-x: scroll;
}

.fs-sp .shipping-nav::-webkit-scrollbar {
  display: none;
}

.shipping-nav li a {
  white-space: nowrap;
}
</style>
<style lang='scss'>
.btn-close-modal {
  font-size: 1.3rem !important;
  padding: 1rem 2rem;
  line-height: 100% !important;
  height: unset !important;
}

.table-step {
  width: 100%;
}

.table-step td {
  width: 25%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  padding-top: 0.5rem;
}

.b2c-bg-top {
  background: rgba(237, 31, 36, 1);
  display: flex;
  margin-bottom: 0px;
}

.b2c-top-left::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}

.b2c-top-left {
  height: 44px;
  padding: 8px 14px;
  width: 20%;
}

.b2c-top-right {
  width: 20%;
}

.b2c-top-right1::before {
  width: 20%;
}

.b2c-top-center {
  width: 80%;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding-top: 7px;
}

.b2c-steps {
  width: 100%;
  height: 42px;
  background: #d71a1f;
}

.b2c-text-solo {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
}

.b2c-bg {
  background: rgba(237, 31, 36, 1);
  padding-bottom: 32px;
}

.b2c-number-087 {
  width: 25%;
  text-align: right;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 36px;
  color: #fff;
  padding-top: 3px;
}

.b2c-number-msisdn {
  width: 50%;
  padding: 0px 10px;
}

.b2c-number-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  letter-spacing: 0px;
  padding-left: 0px;
}

.b2c-number-input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  height: 45px;
  color: #ed1f24;
  width: 100%;
  border: none;
  background: #fff;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  letter-spacing: 3px;
  padding-left: 10px;
}

.b2c-number-btn {
  width: 25%;
}

.b2c-number-btn button {
  border: 1px solid #fff;
  width: 49px;
  height: 45px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE4TDE0LjIyMjMgMTQuMjE1NkwxOCAxOFpNMTYuMzE1OCA5LjE1Nzg5QzE2LjMxNTggMTEuMDU2MyAxNS41NjE3IDEyLjg3NjkgMTQuMjE5MyAxNC4yMTkzQzEyLjg3NjkgMTUuNTYxNyAxMS4wNTYzIDE2LjMxNTggOS4xNTc4OSAxNi4zMTU4QzcuMjU5NSAxNi4zMTU4IDUuNDM4ODYgMTUuNTYxNyA0LjA5NjUgMTQuMjE5M0MyLjc1NDEzIDEyLjg3NjkgMiAxMS4wNTYzIDIgOS4xNTc4OUMyIDcuMjU5NSAyLjc1NDEzIDUuNDM4ODYgNC4wOTY1IDQuMDk2NUM1LjQzODg2IDIuNzU0MTMgNy4yNTk1IDIgOS4xNTc4OSAyQzExLjA1NjMgMiAxMi44NzY5IDIuNzU0MTMgMTQuMjE5MyA0LjA5NjVDMTUuNTYxNyA1LjQzODg2IDE2LjMxNTggNy4yNTk1IDE2LjMxNTggOS4xNTc4OVY5LjE1Nzg5WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==") no-repeat center;
}

.b2c-number--container {
  padding: 0 25px;
  height: 36px;
  background: #d71a1f;
  width: 100%;
}

.b2c-number-except {
  margin: 0 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c-number-except-e {
  margin: 0 25px;
  height: auto;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c-number-type {
  margin-left: 25px;
  margin-right: 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c-number-price {
  margin: 0 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 30px;
}

.b2c-number-except-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}

.b2c-number-except-buttons {
  padding: 0px;
  min-height: 46px;
  overflow: hidden;
}

.b2c-number-except-btn {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K") no-repeat;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 8px 8px 8px 0px;
  color: #fff;
  width: 29px;
  height: 29px;
}

.b2c-number-except-btn-checked {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==") no-repeat;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 8px 8px 8px 0px;
  color: #ed1f24;
  width: 29px;
  height: 29px;
}

.b2c-number-except-colapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}

.b2c-number-except-nocolapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyA3LjE0MjgyTDcgMS4xNDI4MkwxIDcuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}

.b2c-number-type-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}

.b2c-number-type-select option {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.b2c-number-type-select {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  background: none;
  border: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 18px;
  margin-right: 16px;
  padding-right: 16px;
}

.b2c-search-button {
  width: calc(100% - 50px);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  color: #424242;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  height: 48px;
  background: #ffffff;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  margin: 25px;
}

.b2c-search-button-red {
  color: #ffffff !important;
  background: #ed1f24 !important;
}

.b2c-pagination-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding-top: 4px;
  color: #424242;
  width: 55%;
}

.b2c-pagination-title-sort {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #424242;
  background: #fff;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 4px 8px;
  border: none;
  width: 45%;
  text-align: right;
}

.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}

.b2c-badge {
  font-size: 14px;
}

.b2c-buy-btn {
  border: 2px solid #ed1f24;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  font-size: 16px;
  color: #ed1f24;
  height: 34px;
  width: 96px;
}

.b2c-page-msisdn {
  color: #000;
  font-weight: 500;
  font-size: 28px;
  padding-bottom: 12px;
  letter-spacing: 2px;
}

.b2c-camket-text {
  font-size: 14px;
  margin-top: 12px;
}

.b2c-price-text {
  font-weight: 400;
  font-size: 24px;
  padding-top: 12px;
  letter-spacing: 0.5px;
  color: #424242;
}

.b2c-price-text b {
  color: #ed1f24;
}

.b2c-package-bg-selected {
  background: #f9f9f9;
}

.b2c-package-title-selected {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  text-align: left;
  width: 50%;
  font-size: 36px;
  color: #ed1f24;
}

.b2c-package-subtitle-selected {
  font-family: "Roboto";
  font-style: normal;
  text-align: right;
  font-weight: 700;
  font-size: 20px;
  width: 50%;
  color: #424242;
  padding-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: end;
}

.b2c-package-subtitle-selected p {
  padding-bottom: 2px;
}

.b2c-package-brief-selected {
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}

.b2c-package-brief-selected li {
  margin-top: 10px;
}

.b2c-package-brief-selected li::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjM1MTYgMTIuMjkyOUMxMC45NjExIDEyLjY4MzQgMTAuMzI3OSAxMi42ODM0IDkuOTM3NDIgMTIuMjkyOUw5LjE0NDUzIDExLjVDOC44NjgzOSAxMS4yMjM5IDguNDIwNjcgMTEuMjIzOSA4LjE0NDUzIDExLjVDNy44NjgzOSAxMS43NzYxIDcuODY4MzkgMTIuMjIzOSA4LjE0NDUzIDEyLjVMOS45Mzc0MiAxNC4yOTI5QzEwLjMyNzkgMTQuNjgzNCAxMC45NjExIDE0LjY4MzQgMTEuMzUxNiAxNC4yOTI5TDE1LjE0NDUgMTAuNUMxNS40MjA3IDEwLjIyMzkgMTUuNDIwNyA5Ljc3NjE0IDE1LjE0NDUgOS41QzE0Ljg2ODQgOS4yMjM4NiAxNC40MjA3IDkuMjIzODYgMTQuMTQ0NSA5LjVMMTEuMzUxNiAxMi4yOTI5WiIgZmlsbD0iI0VEMUYyNCIvPgo8cGF0aCBkPSJNMjIgMTEuNUMyMiAxNy4yOTkgMTcuMjk5IDIyIDExLjUgMjJDNS43MDEwMSAyMiAxIDE3LjI5OSAxIDExLjVDMSA1LjcwMTAxIDUuNzAxMDEgMSAxMS41IDFDMTcuMjk5IDEgMjIgNS43MDEwMSAyMiAxMS41WiIgc3Ryb2tlPSIjRUQxRjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
  padding-right: 10px;
  vertical-align: sub;
}

.b2c-package-brief-selected .pack-txt {
  text-align: left;
}

.b2c-package-title {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  text-align: center;
  width: 100%;
  font-size: 36px;
  color: #ed1f24;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjU5NDMgMTcuOTQ5N0MxNi4yMDM4IDE4LjM0MDMgMTUuNTcwNiAxOC4zNDAzIDE1LjE4MDEgMTcuOTQ5N0wxMy43NjU5IDE2LjUzNTVDMTMuMzc1MyAxNi4xNDUgMTIuNzQyMiAxNi4xNDUgMTIuMzUxNiAxNi41MzU1VjE2LjUzNTVDMTEuOTYxMSAxNi45MjYxIDExLjk2MTEgMTcuNTU5MiAxMi4zNTE2IDE3Ljk0OTdMMTUuMTgwMSAyMC43NzgyQzE1LjU3MDYgMjEuMTY4NyAxNi4yMDM4IDIxLjE2ODcgMTYuNTk0MyAyMC43NzgyTDIyLjI1MTEgMTUuMTIxM0MyMi42NDE3IDE0LjczMDggMjIuNjQxNyAxNC4wOTc2IDIyLjI1MTEgMTMuNzA3MVYxMy43MDcxQzIxLjg2MDYgMTMuMzE2NiAyMS4yMjc0IDEzLjMxNjYgMjAuODM2OSAxMy43MDcxTDE2LjU5NDMgMTcuOTQ5N1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMiAxNi41QzMyIDI1LjA2MDQgMjUuMDYwNCAzMiAxNi41IDMyQzcuOTM5NTkgMzIgMSAyNS4wNjA0IDEgMTYuNUMxIDcuOTM5NTkgNy45Mzk1OSAxIDE2LjUgMUMyNS4wNjA0IDEgMzIgNy45Mzk1OSAzMiAxNi41WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: 10px;
}

.b2c-package-subtitle {
  font-family: "Roboto";
  font-style: normal;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  color: #424242;
}

.b2c-package-brief {
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}

.b2c-package-brief li {
  margin-top: 10px;
}

.b2c-package-brief li::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNTc0ODkgOC45MDE1OUM4LjE4NDM2IDkuMjkyMTIgNy41NTEyIDkuMjkyMTIgNy4xNjA2OCA4LjkwMTU5TDYuNzU5MDkgOC41QzYuNTU0OTggOC4yOTU5IDYuMjI0MDYgOC4yOTU5IDYuMDE5OTYgOC41VjguNUM1LjgxNTg1IDguNzA0MTEgNS44MTU4NSA5LjAzNTAzIDYuMDE5OTYgOS4yMzkxM0w3LjE2MDY4IDEwLjM3OTlDNy41NTEyIDEwLjc3MDQgOC4xODQzNiAxMC43NzA0IDguNTc0ODkgMTAuMzc5OUwxMS4xOTM5IDcuNzYwODdDMTEuMzk4IDcuNTU2NzcgMTEuMzk4IDcuMjI1ODUgMTEuMTkzOSA3LjAyMTc0VjcuMDIxNzRDMTAuOTg5OCA2LjgxNzY0IDEwLjY1ODggNi44MTc2NCAxMC40NTQ3IDcuMDIxNzRMOC41NzQ4OSA4LjkwMTU5WiIgZmlsbD0iIzQyNDI0MiIvPgo8cGF0aCBkPSJNMTYgOC41QzE2IDEyLjY0MjEgMTIuNjQyMSAxNiA4LjUgMTZDNC4zNTc4NiAxNiAxIDEyLjY0MjEgMSA4LjVDMSA0LjM1Nzg2IDQuMzU3ODYgMSA4LjUgMUMxMi42NDIxIDEgMTYgNC4zNTc4NiAxNiA4LjVaIiBzdHJva2U9IiM0MjQyNDIiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");
  padding-right: 10px;
}

.b2c-package-brief .pack-txt,
.b2c-package-brief-selected .pack-txt {
  text-align: left;
}

.b2c-package-brief-selected .pack-txt,
.b2c-package-brief .pack-txt {
  margin-bottom: 10px;
}

.b2c-float-bg {
  height: 120px;
  background: rgba(237, 31, 36, 1);
  position: absolute;
}

.b2c-cart-price {
  position: absolute;
  min-width: 100px;
  width: auto;
  height: 63px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  right: 20px;
  background: #18d3c9;
  font-style: italic;
  font-size: 16px;
  color: #fff;
  font-weight: 900;
  text-align: center;
  padding: 13px;
}

.b2c-cart-price span {
  font-size: 24px;
}

.b2c-cart-add-more {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.b2c-cart-info-title {
  height: 46px;
  background: #eaeaea;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #222222;
  padding: 10px 24px;
  border-bottom: 1px solid #e0e0e0;
}

.b2c-cart-info-title-text {
  height: 46px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #222222;
  padding: 1rem 1rem;
}

.b2c-agree-text button {
  font-weight: 500;
  font-size: 16px;
  color: #222222;
}

.b2c-store-colapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAyTDYuNSA3TDIgMiIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}

.b2c-store-nocolapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSA3TDYuNSAyTDIgNyIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}

.b2c-bg-selected {
  background: #17e0d5;
}

.b2c-bg-selected .b2c-page-msisdn,
.b2c-bg-selected .b2c-price-text,
.b2c-bg-selected .b2c-camket-text,
.b2c-bg-selected .b2c-price-text b {
  color: #fff;
}

.b2c-text-solo h1 {
  font-size: 28px;
  letter-spacing: 3px;
  font-weight: 500;
  color: #fff;
}

.b2c-text-solo p {
  color: #fff;
  font-size: 16px;
  padding: 0 10%;
}

.b2c-search-button-red:disabled {
  background: #999999 !important;
}

.b2c-cart-bg-del {
  border-radius: 26.8px;
  -webkit-border-radius: 26.8px;
  border: 1.5px solid #ed1f24;
  padding: 6px 16px;
}

.b2c-price-texts {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.b2c-rate-text {
  font-size: 20px;
  line-height: 1.4;
}

.vue-star-rating {
  display: inline-block !important;
}

.b2c-ghtk-fee {
  font-size: 14px;
  text-decoration-line: line-through !important;
}

.b2c-o-h {
  max-width: 100%;
  overflow-x: hidden;
}

.text1 {
  font-size: 12px;
  line-height: 1.5;
}

.b2c-page-bg .custom-checkbox.custom-control {
  padding-left: 2.2rem;
}

.b2c-page-bg .custom-control-label {
  line-height: 1.2;
}

.b2c-pc-pack-content {
  min-height: 550px;
  max-width: 130rem;
  margin-left: auto;
  margin-right: auto;
}

.b2c-pc-pack-content .b2c-pc-info {
  margin-top: 50px;
}

.b2c-pc-pack-content .b2c-pc-info .card-header {
  background: #f8f8f8;
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.b2c-pc-pack-content .b2c-package-bg-selected {
  max-width: 457px;
  margin-left: auto;
  margin-right: auto;
}

.space-between {
  justify-content: space-between;
}

.delivery-type {
  color: #424242;
  padding-top: 20px;
  padding-bottom: 20px;
}

.border-bt {
  border-bottom: 0.7px solid #bfbfbf;
}

.info-delivery__title {
  font-size: 1.8rem;
  line-height: 2.3rem;
}

.info-delivery__sub-title {
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-style: italic;
}

.check-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.check-box>img {
  width: 22px;
  height: 22px;
}

.b2c-pc-header {
  max-width: 130rem;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  border-bottom: 1px solid #dadada;
  width: 100%;
}

.b2c-pc-header {
  display: flex;
}

.b2c-pc-header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 69px;
  color: #424242;
  width: 50%;
}

.b2c-pc-payment {
  margin-top: 50px;
}

.b2c-pc-payment h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  color: #424242;
  text-transform: uppercase;
}

.b2c-pc-pack-content .cart {
  width: 100%;
  background: #FAFAFA;
  border: 1.5px solid #D2D2D2;
  border-radius: 10px;
  padding: 22px 25px;
  height: calc(188.54px + 44px);
  position: relative;
  margin-bottom: 17px;
}

.b2c-pc-pack-content .cart .image {
  width: 154px;
  height: 188.54px;
  background: #FFFFFF;
  box-shadow: 1px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  float: left;
}

.b2c-pc-pack-content .cart .info {
  float: right;
  width: calc(100% - 184px);
}

.b2c-pc-pack-content .cart .info h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-right: 5px;
}

.b2c-pc-pack-content .cart .info .price {
  font-weight: 900;
  font-size: 24px;
  line-height: 15px;
  color: #E9182C;
}

.b2c-pc-pack-content .cart .info .price_box {
  float: right;
  text-align: right;
  padding: 10px 10px 0 0;
}

.b2c-pc-pack-content .cart .info .base_price {
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: #525252;
  line-height: 2;
  text-decoration: line-through;
}

.b2c-pc-pack-content .cart .info .quantity p {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.7;
  padding-bottom: 6px;
}

.b2c-pc-pack-content .cart .info .quantity {
  position: absolute;
  bottom: 22px;
}

.b2c-pc-pack-content .cart .info .amount {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  opacity: 0.8;
  padding: 0px 10px;
  display: inline;
}

.b2c-pc-pack-content .cart .info .quantity svg {
  margin-bottom: 7px;
}

.b2c-pc-pack-content .cart .remove {
  font-weight: 400;
  font-size: 15px;
  line-height: 111.69%;
  text-decoration-line: underline;
  color: #E9182C;
  position: absolute;
  right: 25px;
  bottom: 22px;
}

.b2c-pc-pack-content .cart .features {
  margin-top: 10px;
}

.b2c-pc-pack-content .cart .features span,
.b2c-pc-pack-content .cart .features div {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: block !important;

}

.b2c-pc-pack-content .cart .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 11px;
}

.features .variant-color {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #666;
}

.features .color-text {
  padding-top: 4px;
  padding-right: 6px;
}

.bank-type__container {
  display: grid;
  grid-template-columns: repeat(3, calc(33.333333% - 6.666666px));
  gap: 10px;
}

.bank-type__item {
  border: 1.5px solid #E9182C;
  border-radius: 16px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  cursor: pointer;
}

.bank-type__item-noselected {
  border: 1.5px solid #B3B3B3;
  border-radius: 16px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  cursor: pointer;
}

.bank-type__item-noselected img {
  // width: 100%;
  max-height: 100%;
  max-width: 90%;
}

.bank-type__item img {
  // width: 100%;
  max-height: 100%;
  max-width: 90%;
}

.f-bold {
  font-weight: bold !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.more-views__container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-view__btn {
  background-color: #C8C8C8;
  color: white;
  border-radius: 19px;
  width: 89px;
  height: 27px;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.more-view__btn:hover {
  background-color: #969696;
}

.compare-package {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #ED1F24;
  cursor: pointer;
  width: fit-content;
}

.text-note {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #ED1F24;
  cursor: pointer;
  width: fit-content;
}

.installment-info-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #222222;
  margin-top: 34px;
}

.break-line {
  border-bottom: 1px solid #0000001A;
  width: calc(100% + 1rem);
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.full-w {
  width: calc(100% + 1rem) !important;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  max-width: none !important;
  flex: none !important;
}

.mt-10 {
  margin-top: 10px;
}

.pd-lr-45 {
  padding: 0 45px;
}

.row-price {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
  padding-top: 8px;
  min-height: 46px;
  padding-top: 12px;
}

.name-shopping {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #424242;
  height: 100%;
}

.price-shopping {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  text-align: right;
}

.main-price {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #424242;
  width: 100%;
}

.sub-price {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-decoration-line: line-through;
  color: #424242;
  width: 100%;
}

.be-color-bg {
  background: #FFF2EC;
}

.total-main-price {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ED1F24;
}

.center-text__title {
  display: flex;
  align-items: center;
}

.pd-b-15 {
  padding-bottom: 15px;
}

.apply-endow {
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 10px 0;
  border: 1px solid rgba(228, 228, 228, 0.6);
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
}

.apply-endow:hover {
  background: rgba(228, 228, 228, 0.838);
}

.apply-endow__content {
  flex-grow: 1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #151522;
}

.apply-endow__tag {
  width: 18px;
  height: 18px;
  margin: 0 15px;
}

.apply-endow__next-arr {
  width: 10px;
  height: 10px;
  margin: 0 15px;
}

.apply-endow-container {
  padding: 28px 45px;
}

.cash-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #ED1F24;
  border-radius: 26.8042px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 11px;
  text-align: center;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: pointer;
}

.policy-purchase {
  padding: 0 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1F1F1F;
  margin: 15px 0;
}

.items-container {
  // width: 100%;
  // height: 1000px;
  // display: flex;
  // flex-direction: column;
  /* background: red; */
  padding-right: 50px;
}

.item-container {
  height: 255px;
  width: 100%;
  background: #FAFAFA;
  border: 1.5px solid #D2D2D2;
  border-radius: 10px;
  padding: 22px 25px;
  display: flex;
}

.img-info {
  min-height: 205px;
  height: 100%;
  width: 170px;
  background: #FFFFFF;
  box-shadow: 1px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
}

.item-info {
  // padding-left: 35px;
  display: flex;
  flex-grow: 1;
  width: 0;
  padding-top: 0;
  padding-bottom: 15px;
}

.r-info {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: space-between;
}

.l-info {
  flex-grow: 1;
}

.main-price__item {}

.delete-btn__item {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 111.69%;
  color: #E9182C;
}

.delete-btn__item:hover {
  text-decoration-line: underline;
  cursor: pointer;
}

.main-price__using {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 15px;
  color: #E9182C;
}

.remove-line {
  text-decoration: none;
}

.main-price__old {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: #525252;
  text-decoration: line-through;
  margin-right: 3px;
}

.mt-12 {
  margin-top: 12px;
}

.item-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.item-single-info {
  display: flex;
  margin: 5px 0;
}

.item-single-info__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.7;
}

.item-single-info__name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.9;
  margin-left: 5px;
}

.item-single-info__quantity {
  display: flex;
}

.item-quantity {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 111.69%;
  color: #000000;
  opacity: 0.8;
  display: flex;
  align-items: center;
  padding: 0 5px 0 0 !important;
}

.cur-pointer {
  cursor: pointer;
}

.item-quantity-info.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.b2c-dlg-installment {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 13;
  display: flex;
  justify-content: center;
  align-items: center;
}

.installment-dlg {
  width: 73.75rem;
  max-width: 100%;
  min-height: 40.625rem;

  background: #FFFFFF;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.installment-dlg__header {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 22px;
  color: #353535;
  text-align: center;
  padding: 18px;
  padding-right: 50px;
  background: #F8F8F8;
  border-radius: 20px 20px 0px 0px;
  position: relative;

  .exit-img {
    position: absolute;
    right: 21px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .text-bold {
    font-weight: 600;
  }
}

.table-regulations {
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 22px;
  color: #353535;
  min-width: 73.75rem;

  th,
  td {
    border: 1.5px solid white;
    border-collapse: collapse;
  }

  tr th,
  tr td {
    background-color: #CFD5EB;
    font-weight: 600;
  }

  tr:nth-child(even) th,
  tr:nth-child(even) td {
    background-color: #E9ECF5;
  }

  .table-header th {
    padding-top: 15px;
    padding-bottom: 15px;
    vertical-align: middle;
  }

  .table-body td {
    padding-top: 15px;
    padding-bottom: 15px;
    vertical-align: middle;
    font-weight: 500;
    padding-left: 5px;
    padding-right: 5px;
  }

  .tr-content th {
    padding: 19px 10px 17px 10px;
  }

  .tr-content-button th {
    padding-top: 23px;
    padding-bottom: 46px;
    align-content: center;
    text-align: center;
  }

  .cash-btn-th {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 107px;
    height: 48px;
    background: #ED1F24;
    border-radius: 26px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 11px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: calc(50% - 53.5px);
  }
}

.installment-dlg__body {
  padding: 37px 0 0 0;
  overflow-x: auto;
  border-radius: 0px 0px 20px 20px;

  table {
    width: 100%;
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 22px;
    color: #353535;
    min-width: 73.75rem;

    tr th {
      border-right-color: transparent;
    }

    tr:nth-child(even):not(.tr-content-button) th {
      background-color: #FFECEC;
      border-right-color: #FFECEC;
    }

    tr th:first-child {
      padding-left: 33px;
    }

    // tr th:last-child{
    //   padding-right: 33px;
    // }
    .tr-content th {
      padding: 19px 10px 17px 10px;
    }

    .tr-content-button th {
      padding-top: 23px;
      padding-bottom: 46px;
      align-content: center;
      text-align: center;
    }

    .cash-btn-th {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 107px;
      height: 38px;
      background: #ED1F24;
      border-radius: 26px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 11px;
      text-align: center;
      color: #FFFFFF;
      text-transform: uppercase;
      cursor: pointer;
      margin-left: calc(50% - 53.5px);
    }
  }
}

#modal-dktg .text,
#modal-cttg .text {
  font-size: 1.3rem;
}
</style>
